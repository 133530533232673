<template>
  <div
    v-observe-visibility="observeVisibilityOptions"
    class="video-slot__wrapper"
  >
    <div
      v-if="videoSettings.IsSponsored"
      class="video-slot__sponsored-label font-sans semi-bold"
    >
      Sponsored
    </div>
    <div class="video-slot__title title">{{ videoTitle }}</div>
    <div class="all-news__video-wrapper">
      <a-video
        :video="videoSettings.Video"
        :aspect-ratio="aspectRatios.youtubeVideo"
      />
    </div>
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import AVideo from 'shared/AVideo'
import { aspectRatios } from 'enums/aspectRatios'
import mixins from '@/utils/mixins'

export default {
  name: 'AVideoSlot',
  mixins: [mixins.urlFormatters],
  components: { AVideo },
  props: {
    videoSettings: propValidator([PROP_TYPES.OBJECT]),
    observeVisibilityOptions: propValidator([PROP_TYPES.OBJECT], false, null)
  },
  data() {
    return {
      aspectRatios
    }
  },
  computed: {
    videoTitle() {
      return this.videoSettings.Title || this.videoSettings.Video.Title
    }
  }
}
</script>

<style lang="scss" scoped>
.video-slot__wrapper {
  .video-slot__sponsored-label {
    text-align: right;
    margin-bottom: 5px;
    font-size: 16px;
    color: $c--main;

    @include mobile {
      font-size: 12px;
    }
  }

  .video-slot__title {
    margin-bottom: 10px;
    padding: 0 10px;

    @include mobile {
      margin-bottom: 5px;
      padding: 0;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
</style>
